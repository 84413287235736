var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage,"send-multiple-sms":false,"change-columns-by-client":true},on:{"reload":_vm.updateTable},scopedSlots:_vm._u([{key:"custom-vselect",fn:function(){return [_c('FilterStatusAccount',{attrs:{"filters":_vm.filters[3]}})]},proxy:true},{key:"table",fn:function(){return [_c('b-table',{ref:"refClientsList",attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.myProvider,"fields":_vm.visibleFields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"head(selected)",fn:function(){return [_c('b-form-checkbox',{on:{"input":_vm.selectedAll},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [_c('router-link',{class:[_vm.textLink],attrs:{"to":{
              name: 'claim-department-dashboard',
              params: {
                idClient: JSON.parse(data.item.accounts)[0].account_id,
              },
            },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]),_c('br'),(data.item.user_responsible)?_c('span',{staticClass:"text-warning"},[_c('tabler-icon',{attrs:{"icon":data.item.user_responsible === _vm.currentUser.user_id
                  ? 'StarIcon'
                  : 'LockIcon',"size":"15"}}),_c('strong',[_vm._v("CEO's client")])],1):_vm._e(),_c('div',[_c('span',{staticStyle:{"font-weight":"100"}},[_vm._v(_vm._s(data.item.mobile))])])]}},{key:"cell(account)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticStyle:{"list-style":"none"}},[(_vm.moduleId == 4)?_c('a',{attrs:{"href":'/administration/clients/account/' + account.account_id}},[_vm._v(_vm._s(account.account))]):_c('span',{staticStyle:{"font-weight":"100"}},[_vm._v(_vm._s(account.account))])])}),0),_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticStyle:{"list-style":"none"}},[_c('status-account',{attrs:{"account":account}})],1)}),0)])]}},{key:"cell(fee_charges)",fn:function(data){return _vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('div',{key:index},[_c('span',[_vm._v(_vm._s(account.charges ? "$ " + account.charges : null))])])})}},(
            (_vm.moduleId != 2 && _vm.moduleId != 6) ||
            (_vm.moduleId == 6 && _vm.currentUser.role_id != 4)
          )?{key:"cell(monthly_amount)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticStyle:{"list-style":"none"}},[_vm._v(" $ "+_vm._s(account.monthly_amount)+" ")])}),0)])]}}:null,(
            (_vm.moduleId != 2 && _vm.moduleId != 6) ||
            (_vm.moduleId == 6 && _vm.currentUser.role_id != 4)
          )?{key:"cell(last_payment)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticStyle:{"list-style":"none","position":"relative"}},[_c('span',{staticStyle:{"margin-left":"15px"}},[_vm._v(" "+_vm._s(account.last_payment == "-" ? "" : account.last_payment)+" "),(account.state_lp == 1)?_c('b-img',{staticStyle:{"position":"absolute","left":"0","top":"50%","transform":"translate(0, -50%)"},attrs:{"src":_vm.assetsImg + '/images/icons/lastpayment-out.ico'}}):_c('b-img',{staticStyle:{"position":"absolute","left":"0","top":"50%","transform":"translate(0, -50%)"},attrs:{"src":_vm.assetsImg + '/images/icons/lastpayment-in.ico'}})],1)])}),0)])]}}:null,(
            (_vm.moduleId != 2 && _vm.moduleId != 6) ||
            (_vm.moduleId == 6 && _vm.currentUser.role_id != 4)
          )?{key:"cell(status_payment)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticStyle:{"list-style":"none"}},[_c('status-payment',{attrs:{"account":account}})],1)}),0)])]}}:null,{key:"cell(uncomplete_task)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticStyle:{"list-style":"none"}},[_c('feather-icon',{attrs:{"id":"calendar","icon":"CalendarIcon","size":"20"}}),_c('div',{staticStyle:{"position":"relative","bottom":"15px","left":"6.7px","font-size":"85%","font-weight":"bold"}},[_vm._v(" "+_vm._s(account.task)+" ")])],1)}),0)])]}},{key:"cell(enrol_date)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticStyle:{"list-style":"none"}},[_vm._v(" "+_vm._s(_vm._f("myGlobal")(account.created_at))+" ")])}),0)])]}},(_vm.currentUser.role_id != 3 || _vm.moduleId == 2)?{key:"cell(state_advisor)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticStyle:{"list-style":"none"}},[_c('div',[_c('feather-icon',{style:(account.state_advisor == 1
                        ? 'background:green'
                        : account.state_advisor == 2
                        ? 'background:orange'
                        : account.state_advisor == 3
                        ? 'background:red'
                        : 'background:#ccc'),attrs:{"id":"icon","title":account.state_advisor == 1
                        ? 'Active'
                        : account.state_advisor == 2
                        ? 'Busy'
                        : account.state_advisor == 3
                        ? 'Away'
                        : 'Offline',"icon":"CircleIcon","size":"13"}}),_vm._v(" "+_vm._s(account.advisor_name)+" ")],1)])}),0)])]}}:null,{key:"cell(s_note)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px","list-style":"none"}},_vm._l((JSON.parse(data.item.accounts)),function(account,index){return _c('li',{key:index,staticClass:"li-created-at-account",class:account.s_note <= 1
                    ? ''
                    : account.s_note <= 2
                    ? 'text-warning'
                    : 'text-danger'},[_vm._v(" "+_vm._s(account.last_note)+" ")])}),0)])]}},{key:"cell(access_cred)",fn:function(data){return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.openModalListCredentials(
                JSON.parse(data.item.accounts)[0].account_id,
                JSON.parse(data.item.accounts)[0].id,
                data.item.lead_name
              )}}},[_vm._v("AC")])]}},(
            _vm.currentUser.role_id == 1 ||
            _vm.currentUser.role_id == 2 ||
            _vm.currentUser.role_id == 3
          )?{key:"cell(service)",fn:function(data){return [(
              data.item.user_responsible == _vm.currentUser.user_id ||
              data.item.user_responsible == null
            )?_c('actions-table',{attrs:{"options":_vm.serviceOptions,"row-data":data.item},on:{"onRowDelete":_vm.onRowDelete,"onRowEdit":_vm.onRowEdit,"onRowProcess":_vm.onRowProcess,"modalChangeService":_vm.modalChangeService,"modalAddService":_vm.modalAddService}}):_vm._e()]}}:null],null,true)})]},proxy:true}])}),(_vm.modalSendSms)?_c('modal-send-sms',{attrs:{"modal-send-sms":_vm.modalSendSms,"name-leads":_vm.clientsSms[0],"typesms":0},on:{"closeModal":_vm.closeSendSms}}):_vm._e(),(_vm.modalCall)?_c('modal-call-rounds',{attrs:{"modal-call-rounds":_vm.modalCall,"name-leads":_vm.clientsSms,"type-call":0},on:{"updateTable":_vm.updateTable,"closeModal":_vm.closeModalCallRounds}}):_vm._e(),(_vm.modalSendEmail)?_c('modal-send-email',{attrs:{"modal-send-email":_vm.modalSendEmail,"name-leads":_vm.clientsSms,"typesms":0},on:{"closeModal":_vm.closeSendSms}}):_vm._e(),(_vm.modalOpenAddService)?_c('modal-add-service',{attrs:{"modal-open-add-service":_vm.modalOpenAddService,"details-client":_vm.detailsClient,"type-modal":_vm.typeModal},on:{"hideModal":_vm.closeModalService,"changeProgram":_vm.openModalProgram}}):_vm._e(),(_vm.modalListCredentialsOn)?_c('list-access-credentials-modal',{attrs:{"credential-id":_vm.credentialId,"account-number":_vm.accountNumber,"client-name":_vm.clientNameToPass},on:{"close":_vm.closeModalListCredentials}}):_vm._e(),(_vm.modal.programs)?_c(_vm.modalData.programs.programSelected,{tag:"component",attrs:{"type-modal":_vm.modalData.programs.typeModal,"sales-client":_vm.saleClientP,"programs-all":_vm.programsAll,"users-services":_vm.usersServices,"header-s":_vm.headerS},on:{"closeModal":_vm.hideModalProgram,"changeProgram":_vm.openModalProgram}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }