export default [
  {
    key: "lead_name",
    label: "Name",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "account",
    label: "Account",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "fee_charges",
    label: "Fee & Charges",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "monthly_amount",
    label: "MP",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "last_payment",
    label: "LP",
    class: "text-left ",
    sortable: true,
    visible: true,
  },
  {
    key: "status_payment",
    label: "PT",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "state_advisor",
    label: "Advisor",
    class: "text-left",
    visible: true,
  },
  {
    key: "access_cred",
    label: "Access Credential",
    class: "text-left",
    visible: false,
  },
  {
    key: "s_note",
    label: "LN",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "uncomplete_task",
    label: "UT",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "enrol_date",
    label: "Creation Date",
    class: "text-left",
    sortable: true,
    visible: true,
  },
  {
    key: "service",
    label: "Services",
    class: "text-left",
    visible: true,
  },
]
